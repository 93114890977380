import { RefObject, useEffect } from "react";
import ripple from "ripple-effects";

type TRippleOptions = typeof ripple extends (args0: any, arg1: infer U) => any ? U : never;

const useRippleEffect = (ref: RefObject<HTMLElement>, option?: TRippleOptions | string) => {
	useEffect(() => {
		if (ref.current) {
			const res = ripple(ref.current, {
				background: (typeof option === "string" ? option : undefined) ?? `var(--ripple-color)`,
				opacity: 0.25,
				duration: 700,
				outDuration: 800,
				...(typeof option === "string" ? {} : option ?? {}),
			});

			return () => {
				res.destroy();
			};
		}

		return;
	}, []);
};

export default useRippleEffect;
