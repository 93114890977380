import * as Apollo from "@apollo/client";
import useGetSubscriptionVariables from "@hooks/useGetSubscriptionVariables";

export * from "@apollo/client";

export const useSubscription = <TData = any, TVariables extends Apollo.OperationVariables = Apollo.OperationVariables>(
	subscription: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
	options?: Apollo.SubscriptionHookOptions<Apollo.NoInfer<TData>, Apollo.NoInfer<TVariables>>
): Apollo.SubscriptionResult<TData, TVariables> => {
	const subscriptionVariables = useGetSubscriptionVariables();

	return Apollo.useSubscription(subscription, {
		...options,

		variables: {
			...(options?.variables ?? {}),
			...subscriptionVariables,
		} as any as TVariables,
	});
};

export const useQuery = <TData = any, TVariables extends Apollo.OperationVariables = Apollo.OperationVariables>(
	query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
	options?: Apollo.QueryHookOptions<Apollo.NoInfer<TData>, Apollo.NoInfer<TVariables>>
): Apollo.QueryResult<TData, TVariables> => {
	const subscriptionVariables = useGetSubscriptionVariables();

	const { subscribeToMore, ...retorno } = Apollo.useQuery(query, options);

	return {
		...retorno,

		subscribeToMore: (options) =>
			subscribeToMore({
				...options,

				variables: {
					...(options?.variables ?? {}),
					...subscriptionVariables,
				} as any,
			}),
	};
};
