import { lazy, StrictMode, Suspense, useEffect } from "react";
import ReactGA from "react-ga4";

import { createRoot } from "react-dom/client";
import Spinner from "./Modules/Components/Spinner";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import "./Style/Style.scss";
import { registerSW } from "virtual:pwa-register";
import CustomErrorBoundary from "@components/ErrorBoundary";
import * as Sentry from "@sentry/react";

const ReduxProvider = lazy(() => import("@components/Providers/Provider.Reducer"));
const ApolloProvider = lazy(() => import("./Modules/Components/Providers/Provider.Apollo"));
const CookieProvider = lazy(() => import("@components/Providers/Provider.Cookies"));
const ToastProvider = lazy(() => import("@components/Providers/Provider.Toast"));

import { ThemeContext } from "./Modules/Common/ThemeColors";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const RouterProvider = lazy(() => import("@components/Providers/Provider.Router"));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;

const root = createRoot(container);

registerSW({ immediate: true });

if (import.meta.env.MODE === "production") {
	ReactGA.initialize("G-SN0FS89D33");
}

Sentry.init({
	dsn: "https://afe416b13357bf88c63405a9312acc0f@o935225.ingest.sentry.io/4506304474578944",
	integrations: [
		new Sentry.BrowserTracing({
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
		}),
		new Sentry.Replay({
			// https://docs.sentry.io/platforms/javascript/guides/react/session-replay/configuration
			errorSampleRate: 1.0,
			sessionSampleRate: import.meta.env.MODE === "production" ? 0 : 1.0,
			maxReplayDuration: import.meta.env.MODE === "production" ? 10 * 60000 : undefined, // 10 minutos

			maskAllText: import.meta.env.MODE === "production",
			maskAllInputs: import.meta.env.MODE === "production",
			blockAllMedia: import.meta.env.MODE === "production",
		}),
	],

	tracesSampleRate: 1.0,

	environment: import.meta.env.MODE === "production" ? "production" : "development",

	release: "gluk-bolt@" + import.meta.env.MODE === "production" ? __PROJECT_VERSION : `${__PROJECT_VERSION}-dev`,

	beforeSend(event) {
		if (event.message && (event.message.includes("error loading dynamically imported module") || event.message.includes("Failed to fetch dynamically imported module"))) {
			return null;
		}

		return event;
	},
});

document.documentElement.setAttribute("data-bs-theme", import.meta.env.VITE_SYSTEM_NAME);

root.render(
	<StrictMode>
		<Sentry.ErrorBoundary fallback={(errors) => <CustomErrorBoundary {...errors} />}>
			<Suspense fallback={<Spinner />}>
				<ReduxProvider>
					<ApolloProvider>
						<CookieProvider>
							<ThemeContext>
								<Suspense fallback={<Spinner />}>
									<RouterProvider />

									<ToastProvider />
								</Suspense>
							</ThemeContext>
						</CookieProvider>
					</ApolloProvider>
				</ReduxProvider>
			</Suspense>
		</Sentry.ErrorBoundary>
	</StrictMode>
);
