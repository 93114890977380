import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "./ReduxHooks";

const useGetSubscriptionVariables = () => {
	const [searchParams] = useSearchParams({ u: "1" });
	const idEmpresaAcesso = useSelector((state) => state.Global.acessoSuporte.idEmpresa);

	const retorno = useMemo(() => {
		return {
			__IdEmpresa: searchParams.get("u") || "1",
			__IdEmpresaAcesso: idEmpresaAcesso,
		} as any;
	}, [searchParams.get("u"), idEmpresaAcesso]);

	return retorno;
};

export default useGetSubscriptionVariables;
