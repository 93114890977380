import { FieldPolicy } from "@apollo/client";
import { DateTime } from "luxon";

export const dateTypePolicy: FieldPolicy<Date, string> = {
	merge: (_, incoming) => {
		if (incoming == null) return incoming;

		if ((incoming as any) instanceof Date) return incoming as unknown as Date;
		return DateTime.fromFormat(incoming, "dd/MM/yyyy").toJSDate();
	},
};

export const dateTimeTypePolicy: FieldPolicy<Date, string> = {
	merge: (_, incoming) => {
		if (incoming == null) return incoming;

		if ((incoming as any) instanceof Date) return incoming as unknown as Date;
		return DateTime.fromFormat(incoming, "dd/MM/yyyy HH:mm:ss").toJSDate();
	},
};

export const timeTypePolicy: FieldPolicy<Date, string> = {
	merge: (_, incoming) => {
		if (incoming == null) return incoming;

		if ((incoming as any) instanceof Date) return incoming as unknown as Date;
		return DateTime.fromFormat(incoming, "HH:mm:ss").toJSDate();
	},
};
