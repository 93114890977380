// ** React Imports
import { PropsWithChildren, forwardRef, useImperativeHandle, useRef, useState, useMemo } from "react";

// ** Third Party Components
import classnames from "classnames";

// ** Reactstrap Imports
import ReactButton, { ButtonProps } from "react-bootstrap/Button";
import { To } from "react-router-dom";
import Overlay, { OverlayProps } from "react-bootstrap/Overlay";
import Tooltip, { TooltipProps } from "react-bootstrap/Tooltip";
import useRippleEffect from "@hooks/useRippleEffect";

interface RippleButtonProps extends ButtonProps, PropsWithChildren {
	variant?: ButtonColors;
	color?: never;
	to?: To;

	tooltipProps?: {
		tooltip: string;
		placement?: TooltipProps["placement"];
		container?: OverlayProps["container"];
	};

	rippleColor?: string;
}

const Button = forwardRef<HTMLButtonElement, RippleButtonProps>(({ rippleColor, className, children, tooltipProps, ...props }, ref) => {
	const innerRef = useRef<HTMLButtonElement>(null);

	const [show, setShow] = useState(false);

	const rippleColorVar = useMemo(() => {
		if (rippleColor) return rippleColor;

		let backgroundColor = "light";

		switch (props.variant) {
			case "outline-light":
			case "outline-gray":
			case "outline-secondary":
			case "outline-dark":
			case "light":
			case "gray":
				backgroundColor = "dark";
				break;
			default:
				if (props.variant?.startsWith("outline-")) backgroundColor = props.variant.replace("outline-", "");
				else backgroundColor = "light";
				break;
		}

		return `var(--${backgroundColor})`;
	}, [props.variant]);

	useImperativeHandle(ref, () => innerRef.current as HTMLButtonElement);

	useRippleEffect(innerRef);

	return (
		<>
			<ReactButton
				ref={innerRef}
				className={classnames("waves-effect ripple__fluid", className)}
				{...props}
				onMouseEnter={(e) => {
					setShow(true);
					props.onMouseEnter?.(e);
				}}
				onMouseLeave={(e) => {
					setShow(false);
					props.onMouseLeave?.(e);
				}}
				style={{
					...props.style,
					"--ripple-color": rippleColorVar,
				}}
			>
				{children}
			</ReactButton>
			{tooltipProps && innerRef.current != null && (
				<Overlay
					container={tooltipProps.container}
					target={innerRef}
					placement={tooltipProps.placement}
					show={show}
					popperConfig={{
						modifiers: [
							{
								name: "preventOverflow",
								options: {
									rootBoundary: "viewport",
									tether: false,
								},
							},
						],
					}}
				>
					{(props) => (
						<Tooltip id={`tooltip-button`} {...props}>
							{tooltipProps.tooltip}
						</Tooltip>
					)}
				</Overlay>
			)}
		</>
	);
});

Button.displayName = "RippleButton";

export default Button;
