import LoadingIcon from "@src/Assets/Icones/LoadingIcon";
import Logo from "@src/Assets/Images/Logo";
import { FC } from "react";

const Spinner: FC = () => {
	return (
		<div className="fallback-spinner app-loader">
			<Logo />

			<LoadingIcon />
		</div>
	);
};

export default Spinner;
