import { FC, PropsWithChildren } from "react";
import { useEffect, useState, createContext } from "react";

type TThemeColors = {
	colors: {
		primary: {
			light: string;
			main: string;
		};
		secondary: {
			light: string;
			main: string;
		};
		success: {
			light: string;
			main: string;
		};
		danger: {
			light: string;
			main: string;
		};
		warning: {
			light: string;
			main: string;
		};
		info: {
			light: string;
			main: string;
		};
		dark: {
			light: string;
			main: string;
		};
		purple: {
			light: string;
			main: string;
		};
	};
};

const getHex = (color: string) => window.getComputedStyle(document.body).getPropertyValue(color).trim();

const ThemeColors = createContext<TThemeColors>({
	colors: {
		primary: {
			light: getHex("--primary").concat("1a"),
			main: getHex("--primary"),
		},
		secondary: {
			light: getHex("--secondary").concat("1a"),
			main: getHex("--secondary"),
		},
		success: {
			light: getHex("--success").concat("1a"),
			main: getHex("--success"),
		},
		danger: {
			light: getHex("--danger").concat("1a"),
			main: getHex("--danger"),
		},
		warning: {
			light: getHex("--warning").concat("1a"),
			main: getHex("--warning"),
		},
		info: {
			light: getHex("--info").concat("1a"),
			main: getHex("--info"),
		},
		dark: {
			light: getHex("--dark").concat("1a"),
			main: getHex("--dark"),
		},
		purple: {
			light: getHex("--purple").concat("1a"),
			main: getHex("--purple"),
		},
	},
});

const ThemeContext: FC<PropsWithChildren> = ({ children }) => {
	const [colors, setColors] = useState<TThemeColors["colors"]>({
		primary: {
			light: getHex("--primary").concat("1a"),
			main: getHex("--primary"),
		},
		secondary: {
			light: getHex("--secondary").concat("1a"),
			main: getHex("--secondary"),
		},
		success: {
			light: getHex("--success").concat("1a"),
			main: getHex("--success"),
		},
		danger: {
			light: getHex("--danger").concat("1a"),
			main: getHex("--danger"),
		},
		warning: {
			light: getHex("--warning").concat("1a"),
			main: getHex("--warning"),
		},
		info: {
			light: getHex("--info").concat("1a"),
			main: getHex("--info"),
		},
		dark: {
			light: getHex("--dark").concat("1a"),
			main: getHex("--dark"),
		},
		purple: {
			light: getHex("--purple").concat("1a"),
			main: getHex("--purple"),
		},
	});

	useEffect(() => {
		if (window !== undefined) {
			const obj = {
				primary: {
					light: getHex("--primary").concat("1a"),
					main: getHex("--primary"),
				},
				secondary: {
					light: getHex("--secondary").concat("1a"),
					main: getHex("--secondary"),
				},
				success: {
					light: getHex("--success").concat("1a"),
					main: getHex("--success"),
				},
				danger: {
					light: getHex("--danger").concat("1a"),
					main: getHex("--danger"),
				},
				warning: {
					light: getHex("--warning").concat("1a"),
					main: getHex("--warning"),
				},
				info: {
					light: getHex("--info").concat("1a"),
					main: getHex("--info"),
				},
				dark: {
					light: getHex("--dark").concat("1a"),
					main: getHex("--dark"),
				},
				purple: {
					light: getHex("--purple").concat("1a"),
					main: getHex("--purple"),
				},
			};

			setColors({ ...obj });
		}
	}, []);

	return <ThemeColors.Provider value={{ colors }}>{children}</ThemeColors.Provider>;
};

export { ThemeColors, ThemeContext };
