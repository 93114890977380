import { ApolloError } from "@apollo/client";
import { getErrorValues } from "@utils";
import Swal, { SweetAlertResult, SweetAlertOptions } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ReactSweetAlert = withReactContent(Swal);

export const DismissReason = Swal.DismissReason;

export default ReactSweetAlert;

interface IDropdownDefaultClickProps {
	<TReturnType = undefined, TDismissType = undefined>(
		props: {
			title: string;
			button?: {
				confirm: {
					text?: string;
					className?: string;
				};
				cancel: {
					text?: string;
					className?: string;
				};
			};

			preConfirm?: (resolve: (value?: unknown) => void, reject: (value?: unknown) => void) => Promise<void> | void;
			onConfirm?: (result: SweetAlertResult) => TReturnType;
			onDismiss?: (result: SweetAlertResult) => TDismissType;
			body?: React.ReactNode;
			padding?: string;
		} & Omit<SweetAlertOptions, "title" | "button" | "preConfirm" | "onConfirm" | "onDismiss" | "body" | "padding">
	): Promise<Awaited<TReturnType | TDismissType>>;
}

export const DropdownDefaultClick: IDropdownDefaultClickProps = async ({ title, button, onConfirm, onDismiss, preConfirm, body, padding, icon, customClass, ...props }) => {
	return ReactSweetAlert.fire({
		title: body ? undefined : title,
		html: body ? (
			<>
				<h2 className="d-block p-0 fs-4 fw-semibold">{title}</h2>
				<div className="d-block px-0 mx-0 fs-6 fw-normal">{body}</div>
			</>
		) : undefined,
		showCancelButton: true,
		confirmButtonText: button?.confirm?.text ?? "Sim",
		cancelButtonText: button?.cancel?.text ?? "Não",
		allowOutsideClick: false,
		allowEscapeKey: false,
		customClass:
			typeof customClass === "string"
				? customClass
				: {
						confirmButton: button?.confirm?.className ?? "btn btn-success text-white",
						cancelButton: button?.cancel?.className ?? "btn btn-outline-danger ms-1",
						...customClass,
				  },
		buttonsStyling: false,
		showLoaderOnConfirm: true,
		padding: padding ?? "2em 0 2.8em",
		icon,

		preConfirm: preConfirm ? () => new Promise((resolve, reject) => preConfirm(resolve, reject)) : undefined,

		...props,
	}).then(async (result) => {
		if (result.isConfirmed) {
			return await onConfirm?.(result);
		} else if (result.isDismissed) {
			return await onDismiss?.(result);
		}
		return undefined as any;
	});
};

export const ModalLimiteUso = async (error: ApolloError) => {
	const { link, btn } = getErrorValues(error);

	return await ReactSweetAlert.fire({
		title: "Atenção",
		html: error.message,
		confirmButtonText: btn || "Ok",
		cancelButtonText: "Cancelar",
		allowOutsideClick: false,
		allowEscapeKey: true,
		showCancelButton: true,
		customClass: {
			confirmButton: "btn btn-dark-blue text-white",
			cancelButton: "btn btn-outline-danger",
			actions: "d-flex justify-content-center gap-1 align-items-center mt-2",
			htmlContainer: "fs-6",
			title: "pt-25",
			icon: "swal2-icon-small",
		},
		icon: "warning",
		buttonsStyling: false,
		keydownListenerCapture: true,
		focusConfirm: false,
		didOpen: () => {
			(document.getElementsByClassName("swal2-confirm")[0] as HTMLButtonElement).blur();
			(document.getElementsByClassName("swal2-confirm")[0].parentNode as HTMLButtonElement).blur();
		},
	}).then(async (result) => {
		if (result.isConfirmed) {
			// abre o link
			window.open(link, "_blank");
		}
	});
};
