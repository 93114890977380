import { useMediaQuery } from "@hooks/useMediaQuery";
import { FC, useEffect } from "react";
import Button from "./Button";
import SuporteTomTicket from "../Sistema/Pages/Sistema.SuporteTomTicket";

type ErrorBoundaryProps = {
	error: Error;
	componentStack: string;
	eventId: string;
	resetError(): void;
};

const CustomErrorBoundary: FC<ErrorBoundaryProps> = ({ error }) => {
	const mobile = useMediaQuery("(max-width: 768px)");

	if ((error.message && error.message.includes("error loading dynamically imported module")) || error.message.includes("Failed to fetch dynamically imported module")) {
		useEffect(() => {
			setTimeout(() => {
				window.location.reload();
			}, 5000);
		}, []);

		return (
			<main className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center p-5">
				<h1>Uma atualização está disponível, a página será recarregada automaticamente em alguns segundos.</h1>

				<p>Caso a página não recarregue, clique no botão abaixo. Se a mensagem persistir, entre em contato com o suporte.</p>

				<div className="d-flex mb-2">
					<Button variant="outline-info" onClick={() => window.location.reload()} className="me-2">
						Recarregar
					</Button>

					<span className="border border-dark rounded">
						<SuporteTomTicket isButton={true} changeOnSm={false} />
					</span>
				</div>
			</main>
		);
	}

	return (
		<main className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center p-5">
			<h1 className="mb-25">Ocorreu um erro</h1>

			<p>Tente recarregar a página, caso o erro persista, entre em contato com o suporte.</p>

			<div className="d-flex mb-2">
				<Button variant="outline-info" onClick={() => window.location.reload()} className="me-2">
					Recarregar
				</Button>

				<span className="border border-dark rounded">
					<SuporteTomTicket isButton={true} changeOnSm={false} />
				</span>
			</div>

			<details>
				<summary className="text-center">Detalhes do erro</summary>
				<pre
					style={{
						maxWidth: "90vw",
					}}
				>
					<b>{error.message}</b>
					<br />
					<code>{error.stack}</code>
				</pre>
			</details>
		</main>
	);
};

export default CustomErrorBoundary;
