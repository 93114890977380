import { isNotaPratica } from "@utils";
import { FC } from "react";

const Logo: FC = () => {
	return !isNotaPratica() ? (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="45px"
			height="45px"
			style={{
				shapeRendering: "geometricPrecision",
				textRendering: "geometricPrecision",
				fillRule: "evenodd",
				clipRule: "evenodd",
			}}
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 45 45"
		>
			<g>
				<path
					style={{
						opacity: 0.997,
					}}
					fill="#febb1b"
					d="M7.299 -0.074h30.251q5.87 1.505 7.374 7.374v30.251q-1.505 5.87 -7.374 7.374H7.299Q1.429 43.42 -0.075 37.551V7.3Q1.429 1.43 7.299 -0.074"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#1b1b1b"
					d="M12.116 12.718a255.375 255.375 0 0 1 9.482 8.654q0.619 0.626 1.355 0.151a263.25 263.25 0 0 1 9.632 -8.804h4.214v4.064a1077.75 1077.75 0 0 0 -11.288 10.309q-2.421 1.788 -5.267 0.753a119.25 119.25 0 0 1 -6.472 -5.794q0 -0.151 -0.151 -0.151 -0.013 -0.123 -0.151 -0.151 -0.226 5.266 -0.151 10.536H7.901v-0.151q0.226 -9.668 0.151 -19.415z"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#352c1d"
					d="M12.116 12.718H8.053q0.074 9.745 -0.151 19.415V12.567q2.149 -0.074 4.214 0.151"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#58441e"
					d="M32.583 12.718q2.141 -0.224 4.364 -0.151 0.074 2.149 -0.151 4.214v-4.064z"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#46381d"
					d="M13.62 21.898a184.5 184.5 0 0 0 -0.151 10.536 51.75 51.75 0 0 1 -5.569 -0.151h5.418q-0.074 -5.269 0.151 -10.536 0.137 0.028 0.151 0.151"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#d99d1e"
					d="M13.62 21.898q0.151 0 0.151 0.151l-0.3 10.384q-0.074 -5.307 0.151 -10.536"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#56431e"
					d="M31.229 27.767h5.718v4.665h-5.718z"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 1,
					}}
					fill="#1b1b1b"
					d="M31.38 27.918h5.418v4.364h-5.418z"
				/>
			</g>
		</svg>
	) : (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32px"
			height="32px"
			style={{
				shapeRendering: "geometricPrecision",
				textRendering: "geometricPrecision",
				fillRule: "evenodd",
				clipRule: "evenodd",
			}}
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 32 32"
		>
			<g>
				<path
					style={{
						opacity: 0.998,
					}}
					fill="#00c2dd"
					d="M25.762 5.628c2.578 0.002 4.362 1.188 5.349 3.556 0.376 0.952 0.619 1.937 0.728 2.956 0.163 1.389 0.163 2.778 0 4.166 -0.15 1.184 -0.485 2.312 -1.007 3.384 -0.438 0.877 -1.032 1.632 -1.783 2.265 -1.209 0.946 -2.583 1.36 -4.123 1.242 -1.405 -0.09 -2.581 -0.648 -3.529 -1.676 -0.004 1.728 -0.007 3.456 -0.011 5.183 -0.07 0.788 -0.422 1.415 -1.055 1.88 -0.479 0.326 -1.009 0.474 -1.59 0.444 -0.759 -0.009 -1.517 -0.02 -2.276 -0.032 -0.002 -6.883 0 -13.765 0.005 -20.648 0.064 -0.799 0.43 -1.415 1.098 -1.847 0.317 -0.19 0.659 -0.301 1.028 -0.332 0.735 -0.005 1.471 -0.007 2.206 -0.005 -0.109 0.746 -0.214 1.492 -0.316 2.238 0.679 -0.807 1.483 -1.455 2.415 -1.944 0.701 -0.376 1.443 -0.633 2.228 -0.771a6.302 6.302 0 0 1 0.632 -0.059Zm-1.746 4.862c0.289 -0.004 0.579 0.001 0.867 0.016 0.701 0.024 1.185 0.358 1.451 1.001a4.725 4.725 0 0 1 0.278 1.092c0.089 0.704 0.13 1.411 0.123 2.12 0.01 0.693 -0.038 1.382 -0.145 2.067 -0.07 0.479 -0.224 0.929 -0.461 1.349 -0.322 0.503 -0.781 0.801 -1.376 0.894 -1.438 0.28 -2.557 -0.185 -3.357 -1.398 -0.007 -1.742 -0.007 -3.484 0 -5.226 0.306 -0.535 0.704 -0.99 1.194 -1.365 0.426 -0.31 0.901 -0.493 1.424 -0.552Z"
				/>
			</g>
			<g>
				<path
					style={{
						opacity: 0.998,
					}}
					fill="#00c2dd"
					d="M-0.005 23.309v-15.229c0.172 -0.767 0.622 -1.3 1.349 -1.601 0.214 -0.079 0.436 -0.121 0.664 -0.129 0.789 -0.005 1.578 -0.007 2.367 -0.005a359.011 359.011 0 0 1 -0.337 2.217c0.837 -0.951 1.842 -1.67 3.015 -2.158 1.017 -0.431 2.077 -0.627 3.181 -0.589 0.732 0.028 1.425 0.206 2.078 0.535 0.91 0.508 1.544 1.252 1.901 2.233 0.21 0.585 0.331 1.188 0.364 1.81 0.016 4.305 0.021 8.61 0.016 12.916h-5.055c0.002 -3.952 0 -7.904 -0.005 -11.855 -0.009 -0.367 -0.073 -0.724 -0.193 -1.071 -0.171 -0.467 -0.501 -0.744 -0.991 -0.83 -0.605 -0.093 -1.166 0.025 -1.681 0.353 -0.773 0.513 -1.278 1.222 -1.515 2.126a4.178 4.178 0 0 0 -0.129 0.846l-0.011 8.343c-0.091 0.834 -0.503 1.45 -1.237 1.847 -0.301 0.147 -0.618 0.226 -0.953 0.236 -0.942 0.005 -1.885 0.007 -2.827 0.005Z"
				/>
			</g>
		</svg>
	);
};

export default Logo;
